import React from "react"
import Layout from "../components/layout"
import { Link } from "gatsby"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { LazyLoadComponent } from "react-lazy-load-image-component"
// import PartnerSwiper from "../components/swiper/swiper-partner.js"
import { IoIosPeople } from "react-icons/io"
import { RiUserHeartLine } from "react-icons/ri"
import { RiTimerFlashFill } from "react-icons/ri"
import { FaUserClock } from "react-icons/fa"
// import Cases from "../components/cases"
import Banner from "../components/hero/hero-ai-consulting.js"
// import Testimonials from "../components/swiper/testimonial-swiper"
import loadable from "@loadable/component"
import pMinDelay from "p-min-delay"

const PartnerSwiper = loadable(() =>
  pMinDelay(import("../components/swiper/swiper-partner.js"), 500)
)

const Testimonials2 = loadable(() =>
  pMinDelay(import("../components/swiper/testimonial-swiper"), 500)
)

const Cases = loadable(() => pMinDelay(import("../components/cases"), 500))

const Ai = loadable(() =>
  pMinDelay(import("../components/new-quandary/ai-consult"), 800)
)

class QuandaryBuildPage extends React.Component {
  render() {
    return (
      <Layout banner={<Banner />} location={this.props.location}>
        <Seo
          title="AI Consulting | Quandary Consulting Group"
          description="Solve complex problems, make informed decisions, and increase your business efficiency with artificial intelligence (AI)"
        />

        <PartnerSwiper />
        <section
          style={{
            padding: "3rem 0",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mx-auto text-center">
                <h2 className="text-left-mobile font-weight-medium font-size-3 text-cyan">
                  A Tangible Competitive Advantage
                </h2>
                <h4 className="text-left-mobile font-weight-medium page-title font-size-3">
                  Your AI investments need to be both strategic and intentional
                  to have a measurable impact. Quandary helps you quickly turn
                  your aspirations into tangible outcomes.
                </h4>
                <Link
                  aria-label="link"
                  className="speak-to-btnx btn btn-primary btn-arrow mt-3"
                  to="/contact/"
                >
                  Book Your Consultation
                </Link>
              </div>
            </div>
          </div>
        </section>
        <section
          id="it-consulting"
          className="it-consulting"
          style={{ padding: "0 0 3rem 0" }}
        >
          <div className="container pt-5">
            {/* <div className="row">
              <div className="col-lg-12 text-center">
                <h2>Technology Consulting Services</h2>
              </div>
            </div> */}
            <div
              className="row align-items-center flex-column-reverse flex-lg-row flex-md-row"
              style={{
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px",
                marginTop: "3rem",
              }}
            >
              <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile">
                <h3 className="mt-0">Machine Learning</h3>
                <p className="mt-4">
                  From upskilling your people to building out data models,
                  machine learning (ML) is an essential component of every
                  modern business. We can help build and optimize your ML
                  capability to streamline operations, simplify complex
                  decisions, and build on what you already know.
                </p>
              </div>
              <div className="col-lg-6 col-md-6 py-lg-3 py-3">
                <StaticImage
                  placeholder="blurred"
                  className="rounded d-flex"
                  src="../images/new-images/machine-learning.jpg"
                  alt="Machine Learning"
                  layout="constrained"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="row align-items-center mt-3">
              <div className="col-lg-6 col-md-6 py-lg-4 py-3">
                <StaticImage
                  placeholder="blurred"
                  className="rounded d-flex"
                  src="../images/new-images/generative-ai.jpg"
                  alt="Generative AI"
                  layout="constrained"
                  loading="lazy"
                />
              </div>
              <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 mt-0 text-center mb-lg-0 mb-md-0 mb-3 px-md-5 text-left-mobile">
                <h3 className="mt-0">Generative AI</h3>
                <p className="mt-4">
                  We’re using generative AI to augment human potential. It’s not
                  pattern recognition—it’s pattern generation. When used
                  responsibly to empower your teams, generative AI can help you
                  analyze vast amounts of data, capitalize on customer feedback,
                  and make predictions about the future.
                </p>
              </div>
            </div>
            <div
              className="row align-items-center flex-column-reverse flex-lg-row flex-md-row mt-3"
              style={{ boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 100px" }}
            >
              <div className="col-lg-6 col-md-6 text-lg-left text-md-left mt-lg-0 mt-md-0 text-center mb-lg-0 mb-md-0 mb-4 px-md-5 text-left-mobile">
                <h3 className="text-left-mobile mt-0">Intelligent Products</h3>
                <p className="text-left-mobile mt-4">
                  Our experts use AI, ML, and automation to build intelligent
                  products that help organizations scale and grow. We can help
                  improve the quality and value of your data, delight users with
                  targeted experiences, and create a foundation for scalable
                  solutions.
                </p>
              </div>
              <div className="col-lg-6 col-md-6 py-lg-3 py-3">
                <StaticImage
                  placeholder="blurred"
                  className="rounded d-flex"
                  src="../images/new-images/intelligent-products.jpg"
                  alt="Intelligent Products"
                  layout="constrained"
                  loading="lazy"
                />
              </div>
            </div>
            <div className="text-center">
              <Link
                aria-label="link"
                className="speak-to-btnx btn btn-primary btn-arrow mt-3"
                to="/contact/"
              >
                Book Your Consultation
              </Link>
            </div>
          </div>
        </section>

        <section
          style={{
            background: "#fff",
            padding: "3rem 0",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h2 className="text-left-mobile font-weight-medium font-size-3 text-cyan">
                  Change How Work is Done and Achieve Tangible Business Value
                </h2>
                <p className="mt-4">
                  AI is a strategic priority for most corporations, yet over 87%
                  of AI projects never make it past the proof-of-concept stage.
                  As the AI space constantly evolves and new challenges are
                  presented almost daily, there is a myriad of potential threats
                  that end up stymieing progress within organizations.​
                </p>
                <p>
                  An end-to-end understanding of AI, from collecting use cases
                  across the business to implementation, is crucial for success.
                  ​
                </p>
              </div>
              <div className="col-lg-6 align-self-center pt-3">
                <LazyLoadComponent>
                  <StaticImage
                    placeholder="blurred"
                    className="rounded d-flex"
                    src="../images/new-images/ai-future.jpg"
                    alt="Change How Work is Done and Achieve Tangible Business Value"
                    layout="constrained"
                    loading="lazy"
                  />
                </LazyLoadComponent>
              </div>
            </div>
            <div className="pt-5">
              <Ai />
            </div>
          </div>
        </section>

        {/* <section
          className="bg-white quandary-connect-glance"
          style={{ padding: "3rem 0" }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12 text-center">
                <h2>Transform How Your Business Functions</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3 col-md-6 mt-5 text-center">
                <RiUserHeartLine />
                <p className="h5 my-4" style={{ fontWeight: "bold" }}>
                  Reduce Risk
                </p>
                <p>
                  Deploy secure, reliable systems that streamline everything.
                </p>
              </div>
              <div className="col-lg-3 col-md-6 mt-5 text-center">
                <IoIosPeople />
                <p className="h5 my-4" style={{ fontWeight: "bold" }}>
                  Improve Results
                </p>
                <p>
                  Overdeliver on fast turnarounds without burning out your team.
                </p>
              </div>
              <div className="col-lg-3 col-md-6 mt-5 text-center">
                <RiTimerFlashFill />
                <p className="h5 my-4" style={{ fontWeight: "bold" }}>
                  Stay Agile
                </p>
                <p>
                  Adjust to changing markets rapidly and outperform your
                  competitors.
                </p>
              </div>
              <div className="col-lg-3 col-md-6 mt-5 text-center">
                <FaUserClock />
                <p className="h5 my-4" style={{ fontWeight: "bold" }}>
                  Eliminate Inefficiency
                </p>
                <p>Drastically cut costs and waste business-wide.</p>
              </div>
            </div>
          </div>
        </section> */}

        {/* <section id="case-studies-container">
          <div id="case-studies" className="bg-indigo">
            <div className="container">
              <div className="intro text-center">
                <div className="row">
                  <div className="col-md-10 mx-auto text-left-mobile">
                    <p className="h5 text-light-blue text-uppercase font-weight-bold m-0">
                      Case Studies
                    </p>
                    <h2 className=" my-2 text-white">
                      We Save Clients 1000s of Hours. Every Year.
                    </h2>
                    <p className="text-white">
                      We helped a national healthcare provider save over 9,000
                      hours in weekly labor costs by developing a single
                      application...
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <Cases allSanityCaseStudy={this.props.data.allSanityCaseStudy} />
          </div>
        </section> */}

        <Testimonials2 />

        {/* <section
          style={{
            padding: "3rem 0",
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-10 mx-auto text-center">
                <h2 className="text-left-mobile">
                  Get More Out of Your Business Applications
                </h2>
                <p className="text-left-mobile">
                  We help you get more out of your tech, so you can complete
                  your projects faster, exceed your goals, and build the
                  business your customers desire.
                </p>
                <Link
                  aria-label="link"
                  className="speak-to-btnx btn btn-primary btn-arrow mt-3"
                  to="/contact/"
                >
                  Speak to a Technology Consultant
                </Link>
              </div>
            </div>
          </div>
        </section> */}
      </Layout>
    )
  }
}

export default QuandaryBuildPage

export const AiConsultingPageQuery = graphql`
  query AiConsultingPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
    allSanityCaseStudy(sort: { fields: [publishedAt], order: DESC }, limit: 3) {
      edges {
        node {
          title
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          bannerImage {
            asset {
              gatsbyImageData(
                fit: FILL
                placeholder: BLURRED
                sizes: ""
                layout: CONSTRAINED
              )
            }
          }
          slug {
            current
          }
        }
      }
    }
  }
`
